import React from 'react'
import { Helmet } from "react-helmet"
import { withPrefix } from 'gatsby'
import Portfolio from '../components/Portfolio'
import { Link } from "react-scroll"
// import GoogleMapReact from 'google-map-react'
import './home.css'
// import agencyCss from '../assets/css/agency.min.css'
// import '../assets/css/bootstrap.min.css'
import Seo from '../components/seo'
import team1 from '../images/team/1.jpg'
import team2 from '../images/team/2.jpg'
import team3 from '../images/team/3.jpg'
import about1 from '../images/1.jpg'
import about2 from '../images/2.jpg'
import about3 from '../images/3.jpg'
import about4 from '../images/4.jpg'
import MyForm from '../components/MyForm'

class Home extends React.Component {

  portfolioLinks = [
    {
      title: 'Threads',
      caption: 'Illustration'
    },
    {
      title: 'Explore',
      caption: 'Graphic Design'
    },
    {
      title: 'Finish',
      caption: 'Identity'
    },
    {
      title: 'Lines',
      caption: 'Branding'
    },
    {
      title: 'Southwest',
      caption: 'Website Design'
    },
    {
      title: 'Window',
      caption: 'Photography'
    }
  ]


  render() {
    return (
      <>
        <Seo title="Home" />
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link href="https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100&family=Roboto+Slab:wght@200&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@200&display=swap" rel="stylesheet" />
          <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css" integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS" crossorigin="anonymous" />
          <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous" />
          <link rel="stylesheet" href="/agency.min.css" />
          <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
          {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.6/umd/popper.min.js" integrity="sha384-wHAiFfRlMFy6i5SRaxvfOCifBUQy1xHdJ/yoi7FRNXMRBu5WHdZYu1hA6ZOblgut" crossorigin="anonymous"></script> */}
          <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/js/bootstrap.min.js" integrity="sha384-B0UglyR+jN6CkvvICOB2joaf5I4l3gm9GU6Hc1og6Ls7i6U/mkkaduKaBhlAXv9k" crossorigin="anonymous"></script>
          <script src={withPrefix('scripts.js')} type="text/javascript" />

          {/* seo website for google search */}
          <script type="application/ld+json">
            {`
              {
              "@context": "https://schema.org",
              "@type": "Textile, Fabric, công ty vải sợi",
              "url": "https://www.tienthanhtextile.com",
              "name": "vải sợi Tân Tiến Thành, Tien Thanh Textile",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+84 28 386644600",
                "contactType": "Sale Support, Phòng kinh doanh"
              }
              }
            `}
          </script>

        </Helmet>
        <div className="Home" id="page-top">

          {/* bang thong bao tam nghi */}
          {/* <CModal
        show={true}
        onClose={()=>{return false}}
        >
          <CModalHeader style={{color:'red', fontWeight:'bold'}} closeButton>
            <h1>
            THÔNG BÁO
            </h1>
          </CModalHeader>
          <CModalBody>
            <h3 style ={{color: 'red'}}>
              Để đảm bảo phòng chống dịch covid-19
            </h3>
            <h2>
            CÔNG TY TÂN TIẾN THÀNH TẠM NGƯNG HOẠT ĐỘNG
            </h2>
            <h4>
              Bắt đầu từ: ngày 13/7/2021 <br />
              Đến hết: ngày 8/8/2021 sẽ có thông báo tiếp <br />
            </h4>
            <h5 style={{color:'blue'}}>
            Rất mong quý Khách Hàng thông cảm về sự bất tiện này
            </h5>
          </CModalBody>
      </CModal> */}

          <nav className="navbar navbar-expand-sm navbar-dark bg-dark fixed-top" id="mainNav">
            <div className="container">

              <Link className="navbar-brand js-scroll-trigger"
                activeClass="active"
                to="page-top"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}> Đầu Trang</Link>

              <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">

                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav text-uppercase ml-auto">
                  <li className="nav-item">
                    <Link className="nav-link "
                      activeClass="active"
                      to="services"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}> Dịch Vụ </Link>

                  </li>
                  <li className="nav-item">
                    <Link className="nav-link "
                      activeClass="active"
                      to="portfolio"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}> Hình Ảnh </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link "
                      activeClass="active"
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}> Lịch Sử </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link "
                      activeClass="active"
                      to="team"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}> Đội Ngũ </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link "
                      activeClass="active"
                      to="contact"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}> Liên Hệ </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>


          <header className="masthead">
            <div className="container">
              <div className="intro-text">
                <div className="intro-lead-in">Công Ty Vải Sợi Tân Tiến Thành</div>
                <div className="intro-heading text-uppercase">Xin Chào Bạn! Chúc Một Ngày Mới An Lành:)</div>
                <Link className="btn btn-primary btn-xl text-uppercase "
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}> Dịch Vụ </Link>
              </div>
            </div>
          </header>


          <section className="page-section" id="services">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="section-heading text-uppercase">DỊCH VỤ</h2>
                  <h3 className="section-subheading text-muted">Sự hài lòng của khách hàng là niềm vui của chúng tôi!</h3>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-md-4">
                  <span className="fa-stack fa-4x">
                    <i className="fa fa-circle fa-stack-2x text-primary"></i>
                    <i className="fa fa-female fa-stack-1x fa-inverse"></i>
                  </span>
                  <h4 className="service-heading">Vải Nữ</h4>
                  <p className="text-muted">Chuyên sản xuất, phân phối các loại vải: Tuyết Mưa(Vitex, TienThanh...), Tuyết Nhung,
                    Tuyết mịn, Carolan, Tăm,...
                  </p>
                </div>
                <div className="col-md-4">
                  <span className="fa-stack fa-4x">
                    <i className="fa fa-circle fa-stack-2x text-primary"></i>
                    <i className="fa fa-male fa-stack-1x fa-inverse"></i>
                  </span>
                  <h4 className="service-heading">Vải Nam</h4>
                  <p className="text-muted">Chuyên sản xuất, phân phối các loại vải: Kaki, Fo, kate silk, kate TienThanh...</p>
                </div>
                <div className="col-md-4">
                  <span className="fa-stack fa-4x">
                    <i className="fa fa-circle fa-stack-2x text-primary"></i>
                    <i className="fa fa-gift fa-stack-1x fa-inverse"></i>
                  </span>
                  <h4 className="service-heading">Vải Học Sinh</h4>
                  <p className="text-muted">Chuyên sản xuất, phân phối các loại vải: Kate Việt thắng, kate lạc đà, rồng, ngựa 9999,
                    kate TienThanh 54, 84. Các mặt hàng quần tây Gân, xước học sinh, me siêu, Fo rộng, hẹp đủ màu
                    ...</p>
                </div>
              </div>
            </div>
          </section>


          <Portfolio portfolioLinks={this.portfolioLinks}></Portfolio>
          <section className="page-section" id="about">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="section-heading text-uppercase">LỊCH SỬ CÔNG TY</h2>
                  <h3 className="section-subheading text-muted">Quá trình hình thành và phát triển qua các giai đoạn</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <ul className="timeline">
                    <li>
                      <div className="timeline-image">
                        <img className="rounded-circle img-fluid" src={about1} alt="" />
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <h4>2000-2011</h4>
                          <h4 className="subheading">Giai đoạn khởi đầu đầy gian trân</h4>
                        </div>
                        <div className="timeline-body">
                          <p className="text-muted">Giai đoạn này nền kinh tế Việt Nam vừa mới hội nhập, khởi sự với ý tưởng cung cấp sản phẩm vải tốt phù hợp với
                            sự người tiêu dùng. Sự khởi nghiệp chỉ là quầy cung cấp sản phẩm vải ở chợ Tân Bình.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-inverted">
                      <div className="timeline-image">
                        <img className="rounded-circle img-fluid" src={about2} alt="" />
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <h4>3/2011</h4>
                          <h4 className="subheading">Thành lập thực thể công ty Tân Tiến Thành</h4>
                        </div>
                        <div className="timeline-body">
                          <p className="text-muted">Khởi đầu một chặng đường mới!</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="timeline-image">
                        <img className="rounded-circle img-fluid" src={about3} alt="" />
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <h4>3/2012</h4>
                          <h4 className="subheading">Thành lập cơ sở dệt thoi</h4>
                        </div>
                        <div className="timeline-body">
                          <p className="text-muted">Hợp tác với những đơn vị kinh nghiệm; xây dựng phân xưởng cho các sản phẩm vải dệt thoi</p>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-inverted">
                      <div className="timeline-image">
                        <img className="rounded-circle img-fluid" src={about4} alt="" />
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <h4>July 2017</h4>
                          <h4 className="subheading">Giai đoạn mở rộng thương hiệu</h4>
                        </div>
                        <div className="timeline-body">
                          <p className="text-muted">Công ty bắt đầu mở rộng sản phẩm, chú trọng hơn đến chất lượng sản phẩm để dần tạo thương hiệu với người tiêu dùng</p>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-inverted">
                      <div className="timeline-image">
                        <h4>Cùng nhau
                          <br />hướng về
                          <br />tương lai!</h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className=" page-section" id="team">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="section-heading text-uppercase">ĐỘI NGŨ NÒNG CỐT</h2>
                  <h3 className="section-subheading text-muted">Đoàn kết, hợp tác cùng nhau phát triển.</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <div className="team-member">
                    <img className="mx-auto rounded-circle" src={team1} alt="the first key member" />
                    <h4>THÀNH NGUYỄN</h4>
                    <p className="text-muted">Giám đốc</p>
                    <ul className="list-inline social-buttons">
                      <li className="list-inline-item">
                        <a href="tel: 0918913060">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="">
                          <i className="fa fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="tel:0985947837">
                          <i className="fa fa-phone"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="team-member">
                    <img className="mx-auto rounded-circle" src={team2} alt="the 2nd key member" />
                    <h4>SƯƠNG TRẦN</h4>
                    <p className="text-muted">Kế toán-Quản lý</p>
                    <ul className="list-inline social-buttons">
                      <li className="list-inline-item">
                        <a href="">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="">
                          <i className="fa fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="tel:0905933683">
                          <i className="fa fa-phone"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="team-member">
                    <img className="mx-auto rounded-circle" src={team3} alt="the 3rd key member" />
                    <h4>THẢO NGUYỄN</h4>
                    <p className="text-muted">Trưởng phòng kinh doanh</p>
                    <ul className="list-inline social-buttons">
                      <li className="list-inline-item">
                        <a href="">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="">
                          <i className="fa fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="tel:0903969255">
                          <i className="fa fa-phone"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <p className="large text-muted">Quí khách hàng nếu có bất cứ nhu cầu, thắc mắc nào có thể liên lạc:</p>
                  <ul style={{ listStyle: 'none' }}>
                    <li > <a href="mailto: congtytantienthanh@gmail.com" className="badge badge-danger">CONGTYTANTIENTHANH@GMAIL.COM.</a></li>
                    <li ><a href="tel: 0283 8644 600" className="badge badge-danger">(028)38.644.600</a>--<a href="tel: 02839.717.469" className="badge badge-danger">(028)39.717.469</a></li>
                    <li ><a target="_blank" href="https://www.google.com/maps/search/2+%C4%90%C6%B0%E1%BB%9Dng+s%E1%BB%91+2,+ph%C6%B0%E1%BB%9Dng+10,+qu%E1%BA%ADn+T%C3%A2n+B%C3%ACnh,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh/@10.7869722,106.64371,17z" className="badge badge-danger">
                      số 2, đường số 2, P.10, Q.Tân Bình, Tp.HCM
                    </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="page-section" id="contact">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="section-heading text-uppercase">LIÊN HỆ</h2>
                  <h3 className="section-subheading text-muted">Được phục vụ quí khách là niềm hân hạnh cho chúng tôi.</h3>
                </div>
              </div>
              <div className="row">
                <MyForm />
              </div>
            </div>
          </section>


          <footer className="footer">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4">
                  {/* <span className="copyright">Copyright &copy; devNerd: okutkd@gmail.com</span> */}
                  <span className="small text-muted">Công Ty TNHH MTV XD-SX-TM-DV Tân Tiến Thành <br /> MST: 	0306708590</span>

                </div>
                <div className="col-md-4">
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href="">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="">
                        <i className="fa fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="tel: 0905933683">
                        <i className="fa fa-phone"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a className="font-weight-normal" href="mailto: congtytantienthanh@gmail.com">congtytantienthanh@gmail.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </>
    )
  }
}

export default Home
