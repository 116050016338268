import React, { useState } from "react"
import axios from "axios"


const MyForm = () => {

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/9d228328-8e00-40e9-8f6f-c813911d4051",
            data: new FormData(form)
        })
            .then(r => {
                handleServerResponse(true, "Thanks!", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };
    return (

        <div className="col-lg-12">
            <form id="contactForm" name="sentMessage" onSubmit={handleOnSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <input className="form-control" id="name" name="name" type="text" placeholder="Họ và tên *" required="required" data-validation-required-message="Please enter your name." />
                            <p className="help-block text-danger"></p>
                        </div>
                        <div className="form-group">
                            <input className="form-control" id="email" name="email" type="email" placeholder="Email *" required="required" data-validation-required-message="Please enter your email address." />
                            <p className="help-block text-danger"></p>
                        </div>
                        <div className="form-group">
                            <input className="form-control" id="phone" name="phone" type="tel" placeholder="Điện thoại *" required="required" data-validation-required-message="Please enter your phone number." />
                            <p className="help-block text-danger"></p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <textarea className="form-control" id="message" name="message" placeholder="Thông tin cần chia sẽ *" required="required" data-validation-required-message="Please enter a message."></textarea>
                            <p className="help-block text-danger"></p>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-lg-12 text-center">
                        <div id="success"></div>
                        <button id="sendMessageButton" className="btn btn-primary btn-lg text-uppercase" type="submit">Gửi Thông Điệp</button>
                    </div>
                </div>
            </form>
        </div>

    );
};

export default MyForm;